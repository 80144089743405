import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';

export interface TokenState {
  token: string;
}

const initialState: TokenState = {
  token: localStorage.getItem('token_value') ?? '',
};

export const TokenStateSlice = createSlice({
  name: 'tokenState',
  initialState: initialState,
  reducers: {
    setTokenState: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { setTokenState } = TokenStateSlice.actions;

export const setToken =
  (token: string): AppThunk =>
  (dispatch) => {
    if (token) {
      localStorage.setItem('token_value', token);
      dispatch(setTokenState(token));
    } else {
      localStorage.removeItem('token_value');
      dispatch(setTokenState(''));
    }
  };

export default TokenStateSlice.reducer;
