import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonRouterLink,
  IonSearchbar,
  IonToolbar,
} from '@ionic/react';
import {
  chevronBackCircleOutline,
  chevronForwardCircleOutline,
  logOutOutline,
} from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';
import { playlist, profileCircle } from '../Icons';
import { ThemeToggle } from '../theme/Theme';
import './Header.scss';

const Header: React.FC = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };
  const handleGoForward = () => {
    history.goForward();
  };

  // TODO: Make forward button go grey when no more forwards to go

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonButtons slot="start">
          <div className="logo"></div>
        </IonButtons>
        <IonButtons className="center">
          <IonIcon
            className="navigationButton"
            icon={chevronBackCircleOutline}
            onClick={handleGoBack}
          />
          <IonIcon
            className="navigationButton"
            icon={chevronForwardCircleOutline}
            onClick={handleGoForward}
          />
          <IonSearchbar
            showClearButton="always"
            placeholder="Search Crates & Tracks"
            color="light"
          ></IonSearchbar>
        </IonButtons>
        <IonButtons slot="end">
          <IonButton>
            <ThemeToggle />
          </IonButton>
          <IonButton>
            <IonIcon icon={playlist} />
          </IonButton>
          <IonRouterLink routerLink="/account">
            <IonButton shape="round">
              <IonIcon icon={profileCircle} />
            </IonButton>
          </IonRouterLink>
          <IonRouterLink routerLink="/logout">
            <IonButton shape="round">
              <IonIcon icon={logOutOutline} />
            </IonButton>
          </IonRouterLink>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
