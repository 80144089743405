import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
  IonRouterLink,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { CrateService } from '../../../services/Crate.service';
import { DateUtilsService } from '../../../services/DateUtils.service';
import { iCrate } from '../../../types/ICrate';

interface iCratesProps {
  category?: string;
  limit?: number;
}

export const CrateGrid: React.FC<iCratesProps> = ({
  category = '',
  limit = 0,
}) => {
  const [crates, setCrates] = useState<iCrate[]>();

  useEffect(() => {
    CrateService.fetchSelectedCrates(
      [CrateService.CRATE_STANDARD, CrateService.CRATE_HACKATHON],
      (data) => {
        if (data.length > 0) {
          if (limit > 0) {
            const limitedCrates = [];
            for (let i = 0; i < limit; i++) {
              limitedCrates.push(data[i]);
            }
            setCrates(limitedCrates);
          } else {
            setCrates(data);
          }
        }
      },
    );
  }, [limit]);

  return (
    <>
      {limit !== 0 && (
        <IonRouterLink routerLink="/crates">
          <IonText color="dark">
            <h2>Latest Crates</h2>
          </IonText>
        </IonRouterLink>
      )}
      <IonGrid>
        {crates &&
          crates.length > 0 &&
          crates.map((crate) => (
            <IonRouterLink routerLink={'/crate/' + crate?._id}>
              <IonCard
                data-playlistId={crate?.['Playlist ID']}
                className="crate-grid-card"
                color="light"
              >
                <img alt={crate.Title} src={crate.Image} />
                <IonCardHeader>
                  <IonCardTitle>{crate.Title}</IonCardTitle>
                  <IonCardSubtitle>Exclusive</IonCardSubtitle>
                  <IonCardSubtitle>
                    Published {DateUtilsService.formatDate(crate.Published)}
                  </IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
            </IonRouterLink>
          ))}
      </IonGrid>
    </>
  );
};
