import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { AppPage } from '../../types/IAppPage';
import {
  allCrates,
  charts,
  folderFlattener,
  genres,
  hacks,
  home,
  myCrates,
  myMusic,
  shazamToCrate,
  spotify,
  textImport,
  vipCrates,
} from '../Icons';
import './Menu.scss';

const appPages: AppPage[] = [
  {
    id: 'home',
    title: 'Home',
    url: '/',
    src: home,
  },
  {
    id: 'all-crates',
    title: 'All crates',
    url: '/crates',
    src: allCrates,
  },
  {
    id: 'charts',
    title: 'Charts',
    url: '/charts',
    src: charts,
  },
  {
    id: 'my-crates',
    title: 'My crates',
    url: '/my-crates',
    src: myCrates,
  },
  {
    id: 'my-music',
    title: 'My Music',
    url: '/my-music',
    src: myMusic,
  },
  {
    id: 'genres',
    title: 'Genres',
    url: '/genres',
    src: genres,
  },
  {
    id: 'hacks',
    title: 'Hacks',
    url: '/hacks',
    src: hacks,
  },
  {
    id: 'vip-crates',
    title: 'VIP crates',
    url: '/vip-crates',
    src: vipCrates,
  },
  {
    id: 'spotify-import',
    title: 'Spotify Import',
    url: '/tools/spotify-import',
    src: spotify,
  },
  {
    id: 'text-import',
    title: 'Text Import',
    url: '/tools/text-import',
    src: textImport,
  },
  {
    id: 'shazam-to-crate',
    title: 'Shazam To crate',
    url: '/tools/shazam-to-crate',
    src: shazamToCrate,
  },
  {
    id: 'folder-flattener',
    title: 'Folder Flattener',
    url: '/tools/folder-flattener',
    src: folderFlattener,
  },
];

const Menu: React.FC = () => {
  const location = useLocation();

  const normalizePath = (path: string) => {
    return path.startsWith('/') ? path.substring(1) : path;
  };

  const isNavActive = (url: string) => {
    const locationPath = normalizePath(location.pathname);
    const normalizedUrl = normalizePath(url);

    if (normalizedUrl.length === 0 && locationPath.length > 0) {
      return false;
    }

    return locationPath.startsWith(normalizedUrl);
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="menu-list">
          {appPages.map((appPage, index) => {
            const { id, url, src, title } = appPage;
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  id={id}
                  className={`${isNavActive(url) ? 'active' : ''} ${id}`}
                  routerLink={url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon aria-hidden="true" slot="start" src={src} />
                  <IonLabel>{title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
