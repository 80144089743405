// header
export const profileCircle = '/assets/icon/profile-circle.svg';
export const playlist = '/assets/icon/playlist.svg';
export const arrowCircleLeft = '/assets/icon/arrow-circle-left.svg';
export const arrowCircleRight = '/assets/icon/arrow-circle-right.svg';

// Sidebar
export const home = '/assets/icon/home.svg';
export const allCrates = '/assets/icon/all-crates.svg';
export const charts = '/assets/icon/charts.svg';
export const myCrates = '/assets/icon/my-crates.svg';
export const myMusic = '/assets/icon/my-music.svg';
export const genres = '/assets/icon/genres.svg';
export const hacks = '/assets/icon/hacks.svg';
export const vipCrates = '/assets/icon/vip-crates.svg';
export const spotify = '/assets/icon/spotify.svg';
export const textImport = '/assets/icon/text-import.svg';
export const shazamToCrate = '/assets/icon/shazam-to-crate.svg';
export const folderFlattener = '/assets/icon/folder-flattener.svg';

// Cloud crates
export const cloudCrates = '/assets/icon/cloud-crates.svg';
export const spotifyFull = '/assets/icon/spotify-full.svg';
export const apple = '/assets/icon/apple.svg';
export const soundcloud = '/assets/icon/apple.svg';
export const tidal = '/assets/icon/tidal.svg';
export const deezer = '/assets/icon/deezer.svg';
export const beatsource = '/assets/icon/beatsource.svg';

// Media
export const playButton = '/assets/icon/play-button.svg';
export const pauseButton = '/assets/icon/pause-button.svg';
