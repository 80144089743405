import { iCrate } from '../types/ICrate';
import { iTrack, iTracks } from '../types/ITrack';
import RequestService from './Request.service';

interface ApiResponse {
  count: number;
  cursor?: number; // the cursor can be optional if not always present
  remaining: number;
  results: any[]; // if you can type the results further, replace `any[]` with the more specific type
}

export class CrateService {
  static CRATE_STANDARD = 'standard';
  static CRATE_HACKATHON = 'hackathon';
  static CRATE_MARKETPLACE = 'marketplace';

  private static crateEndpoints: { [key: string]: string } = {
    [CrateService.CRATE_STANDARD]: 'crates/standard',
    [CrateService.CRATE_HACKATHON]: 'crates/hackathon',
    [CrateService.CRATE_MARKETPLACE]: 'crates/marketplace',
  };

  private static crateFetchFunctions: { [key: string]: () => Promise<any> } = {
    [CrateService.CRATE_STANDARD]: CrateService.fetchStandardCrates,
    [CrateService.CRATE_HACKATHON]: CrateService.fetchHackathonCrates,
    [CrateService.CRATE_MARKETPLACE]: CrateService.fetchMarketplaceCrates,
  };

  public static async fetchCrate(id: string, callback: (data: iCrate) => void) {
    const data = await RequestService.fetch(
      `${process.env.REACT_APP_API_BASE_URL}crates/${id}`,
    );
    return callback(data);
  }

  public static async fetchCratesByType(
    crateType: string,
    callback: (data: any) => void,
  ) {
    if (CrateService.crateEndpoints[crateType]) {
      const data = await CrateService.fetchCratesFromEndpoint(
        CrateService.crateEndpoints[crateType],
      );
      return callback(data);
    }
    throw new Error(`Invalid crate type: ${crateType}`);
  }

  private static async fetchCratesFromEndpoint(
    endpoint: string,
    limit?: number,
  ): Promise<any[]> {
    let results: any[] = [];
    let cursor = 0;
    let counter = 0;

    while (cursor !== undefined) {
      const data: ApiResponse = await RequestService.fetch(
        `${process.env.REACT_APP_API_BASE_URL}${endpoint}`,
        'GET',
        undefined,
        cursor, // Send the cursor to the RequestService.fetch
      );

      results = results.concat(data.results);
      if (data.cursor !== undefined) cursor = cursor + data.count; // Update the cursor value based on the API response

      counter += data.results.length;

      if (limit && counter >= limit) {
        results = results.slice(0, limit);
        break;
      }

      if (data.remaining === 0) {
        break;
      }
    }

    return results;
  }

  public static async fetchSelectedCrates(
    crateTypes: string[],
    callback: (data: any[]) => void,
  ) {
    const fetchPromises = crateTypes.map((crateType) =>
      CrateService.fetchCratesByType(crateType, (d) => d),
    );

    const results = await Promise.all(fetchPromises);
    const combinedResults = results.flat();
    return callback(combinedResults);
  }

  private static async fetchStandardCrates(): Promise<any[]> {
    return CrateService.fetchCratesFromEndpoint(
      CrateService.crateEndpoints[CrateService.CRATE_STANDARD],
    );
  }

  private static async fetchHackathonCrates(): Promise<any[]> {
    return CrateService.fetchCratesFromEndpoint(
      CrateService.crateEndpoints[CrateService.CRATE_HACKATHON],
    );
  }

  private static async fetchMarketplaceCrates(): Promise<any[]> {
    return CrateService.fetchCratesFromEndpoint(
      CrateService.crateEndpoints[CrateService.CRATE_MARKETPLACE],
    );
  }
}
