import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';

import React, { Suspense, startTransition, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from './store/hooks'; // Typed dispatch
import { Switch } from 'react-router';
import { Route, useHistory } from 'react-router-dom';
import Index from './pages/Index';
import Menu from './components/menu/Menu';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import Page from './pages/PageTemplate';

import { RootState } from './store/store';
import './theme/variables.css';
import { setToken } from './store/slices/token-state.slice';
import { bubbleTokenValid, isTokenValid } from './utils/auth';

const Register = React.lazy(() => import('./pages/auth/register/register'));
const LogOut = React.lazy(() => import('./pages/auth/logout/logout'));
const Account = React.lazy(() => import('./pages/account/Account'));
const Login = React.lazy(() => import('./pages/auth/login/login'));
const Crates = React.lazy(() => import('./pages/crates/Crates'));
const Crate = React.lazy(() => import('./pages/crate/Crate'));
const Charts = React.lazy(() => import('./pages/charts/Charts'));
const Chart = React.lazy(() => import('./pages/chart/Chart'));
const MyMusic = React.lazy(() => import('./pages/my-music/MyMusic'));

const Announcement = React.lazy(
  () => import('./pages/announcement/Announcement'),
);
const ForgotPassword = React.lazy(
  () => import('./pages/auth/forgotPassword/forgotPassword'),
);

const ResetPassword = React.lazy(
  () => import('./pages/auth/resetPassword/resetPassword'),
);
const VerifyAccount = React.lazy(
  () => import('./pages/auth/verifyAccount/verifyAccount'),
);

setupIonicReact();

const publicRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <IonRouterOutlet id="main">
      <Switch>
        <Route path="/register" exact={true}>
          <Register />
        </Route>
        <Route path="/verify/:token" exact={true}>
          <VerifyAccount />
        </Route>
        <Route path="/forgot-password" exact={true}>
          <ForgotPassword />
        </Route>
        <Route path="/reset-password/:token" exact={true}>
          <ResetPassword />
        </Route>
        <Route path="/login" exact={true}>
          <Login />
        </Route>
        <Route path="*">
          <Login />
        </Route>
      </Switch>
    </IonRouterOutlet>
  </Suspense>
);
const privateRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <IonRouterOutlet id="main">
      <Route path="/" exact={true}>
        <Index />
      </Route>
      <Route path="/announcement/:url" exact={true}>
        <Announcement />
      </Route>
      <Route path="/charts" exact={true}>
        <Charts />
      </Route>
      <Route path="/chart/:id" exact={true}>
        <Chart />
      </Route>
      <Route path="/crates" exact={true}>
        <Crates />
      </Route>
      <Route path="/crate/:id" exact={true}>
        <Crate />
      </Route>
      <Route path="/my-music" exact={true}>
        <MyMusic />
      </Route>
      <Route path="/page/:name" exact={true}>
        <Page />
      </Route>
      <Route path="/account" exact={true}>
        <Account />
      </Route>
      <Route path="/logout" exact={true}>
        <LogOut />
      </Route>
    </IonRouterOutlet>
  </Suspense>
);

const App: React.FC = () => {
  const token = useSelector((state: RootState) => state.tokenState.token);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const localToken = localStorage.getItem('token_value');
    const bubbleExpiration = localStorage.getItem('bubble_expiration');
    if (
      localToken &&
      isTokenValid(localToken) &&
      bubbleTokenValid(bubbleExpiration)
    ) {
      startTransition(() => {
        dispatch(setToken(localToken));
      });
    } else {
      dispatch(setToken(''));
      localStorage.removeItem('bubble_expiration');
    }
  }, [dispatch]);

  return (
    <IonApp>
      <IonReactRouter>
        {token ? (
          <>
            <Header />
            <IonSplitPane contentId="main" side-min-width="240px">
              <Menu />
              {privateRoutes()}
            </IonSplitPane>
            <Footer />
          </>
        ) : (
          <>{publicRoutes()}</>
        )}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
