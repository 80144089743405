import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { iCategory } from '../../types/ICategory';

interface CategoriesState {
  entities: iCategory[];
  loading: boolean;
  error: string | null | undefined;
}
export const fetchCategories = createAsyncThunk<iCategory[]>(
  'categories/fetchCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/categories.json');
      const data = await response.json();
      return data.response.categories as iCategory[];
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const initialState: CategoriesState = {
  entities: [],
  loading: false,
  error: null,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchCategories.fulfilled,
        (state, action: PayloadAction<iCategory[]>) => {
          state.entities = action.payload;
          state.loading = false;
        },
      )
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default categoriesSlice.reducer;
