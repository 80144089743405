import { IonFooter } from '@ionic/react';
import React from 'react';
import './Footer.scss';

const Footer: React.FC = () => {
  return (
    <IonFooter>
      {/*Maybe we put the audio waveform here, with ability to close/hide*/}
      <div className="ion-hide-lg-down">
        <span>Copyright CrateHackers &copy; {new Date().getFullYear()}</span>
      </div>
      <div className="waveform"></div>
    </IonFooter>
  );
};

export default Footer;
