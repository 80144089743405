export const isTokenValid = (token: string): boolean => {
  try {
    const base64Url = token.split('.')[1]; // payload
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payloadJson = atob(base64);
    const payload = JSON.parse(payloadJson);
    const currentTime = Date.now() / 1000;

    return payload.exp > currentTime;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const bubbleTokenValid = (expiration: string | null): boolean => {
  if (expiration === null) return false;
  return Number(expiration) > Date.now();
};
