import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { ThemeStateSlice } from './slices/theme-state.slice';
import { TokenStateSlice } from './slices/token-state.slice';
import categoriesReducer from './slices/categories.slice';

const store = configureStore({
  reducer: {
    themeState: ThemeStateSlice.reducer,
    tokenState: TokenStateSlice.reducer,
    categories: categoriesReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
