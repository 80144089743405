export class DateUtilsService {
  public static formatDate(seconds: number | undefined): string {
    if (typeof seconds == 'undefined') {
      return '';
    }

    const date = new Date(seconds);
    const month = date.toLocaleString('en-US', { month: 'long' });
    const suffix = DateUtilsService.getDaySuffix(date.getDate());

    return `${month} ${date.getDate()}${suffix}, ${date.getFullYear()}`;
  }

  private static getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
