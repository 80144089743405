import { IonToggle } from '@ionic/react';
import type { ToggleCustomEvent } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux'; // import useSelector
import { toggleTheme } from '../../store/slices/theme-state.slice';
import { RootState } from '../../store/store'; // import RootState
import { useEffect } from 'react'; // import useEffect

export const ThemeToggle = () => {
  const dispatch = useDispatch(); // <- Obtain the dispatch function from Redux
  const theme = useSelector((state: RootState) => state.themeState.theme); // Retrieve the theme state
  const themeToggle = theme === 'dark'; // true if dark mode is enabled

  useEffect(() => {
    // You are already doing this in the redux slice.
    // But it's a good idea to ensure your component is also in sync with the OS settings
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    const onChange = (e: MediaQueryListEvent) => {
      const newTheme = e.matches ? 'dark' : 'light';
      if (newTheme !== theme) {
        // If the new theme is not already the current app theme, dispatch the toggle action
        dispatch(toggleTheme());
      }
    };

    prefersDark.addEventListener('change', onChange);

    // Cleanup the listener when the component is unmounted
    return () => {
      prefersDark.removeEventListener('change', onChange);
    };
  }, [theme, dispatch]); // Run the effect when the theme or dispatch changes

  useEffect(() => {
    document.body.classList.toggle('dark', theme === 'dark');
  }, [theme]);

  const toggleChange = (ev: ToggleCustomEvent) => {
    dispatch(toggleTheme()); // Dispatch the action when theme is toggled
  };

  return (
    <>
      <IonToggle
        checked={themeToggle}
        onIonChange={toggleChange}
        justify="space-between"
      ></IonToggle>
    </>
  );
};
