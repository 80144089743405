import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Announcements } from '../components/announcement/Announcements';
import { CrateGrid } from '../components/crates/crateGrid/CrateGrid';
import './Index.scss';

const Index: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <Announcements limit={3} />
        <CrateGrid limit={12} />
      </IonContent>
    </IonPage>
  );
};

export default Index;
