import {
  IonButton,
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonImg,
  IonRow,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { iAnnouncement } from '../../types/IAnnouncement';
import './Announcements.scss';

export interface iAnnouncementsProps {
  limit?: number;
}

export const Announcements: React.FC<iAnnouncementsProps> = ({ limit = 0 }) => {
  const [announcements, setAnnouncements] = useState<iAnnouncement[]>();

  useEffect(() => {
    fetch('/announcements.json')
      .then((response) => response.json())
      .then((data) => {
        if (data.response.announcements?.length > 0) {
          if (limit > 0) {
            const items = [];
            for (let i = 0; i < limit; i++) {
              items.push(data.response.announcements[i]);
            }
            setAnnouncements(items);
          } else {
            setAnnouncements(data.response.announcements);
          }
        }
      });
  }, [limit]);

  return (
    <>
      <IonGrid className="announcements">
        <IonRow>
          {announcements &&
            announcements.length > 1 &&
            announcements.map((announcement) => (
              <IonCol>
                <IonCard
                  className={'announcement bg-' + announcement?.color}
                  key={announcement.id}
                >
                  <IonCol size="6" className="details">
                    <IonRow>
                      <IonCardSubtitle>
                        {announcement?.subtitle}
                      </IonCardSubtitle>
                      <IonCardTitle>{announcement?.title}</IonCardTitle>
                      <IonButton routerLink={announcement?.url}>
                        {announcement?.cta_label}
                      </IonButton>
                    </IonRow>
                  </IonCol>
                  <IonCol className="image" size="6">
                    <IonImg src={announcement?.image_url}></IonImg>
                  </IonCol>
                </IonCard>
              </IonCol>
            ))}
        </IonRow>
      </IonGrid>
    </>
  );
};
