import store from '../store/store';

export default class RequestService {
  static async fetch(url: string, method = 'GET', body?: any, cursor?: number) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${store.getState().tokenState.token}`,
    };

    const options: RequestInit = {
      method,
      headers,
    };

    if (body) {
      options.body = JSON.stringify(body);
    }
    if (cursor !== undefined) {
      url += (url.includes('?') ? '&' : '?') + `cursor=${cursor}`;
    }
    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  }
}
